import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyBr',
})
export class CurrencyBrPipe implements PipeTransform {
  transform(value: number | undefined | null): string {
    if (value === undefined || value === null) {
      return '';
    }

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
}
