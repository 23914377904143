import { EventEmitter, Injectable } from '@angular/core';
import { LoggedUser, UserCompanyProfile } from './loggeduser.model';
import { STORAGE_KEYS } from './storage_keys.config';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  user: LoggedUser | null;

  onChageContextLocation: EventEmitter<any> = new EventEmitter<any>();
  onChangeUserCompanyProfile: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.user = this.getLocalUser();
  }

  getLocalUser(): LoggedUser | null {
    const usr = localStorage.getItem(STORAGE_KEYS.localUser);
    if (usr == null) {
      return null;
    } else {
      return JSON.parse(usr);
    }
  }

  setLocalUser(obj: LoggedUser | null) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.localUser);
    } else {
      localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }
    this.user = obj;
  }

  setUserCompanyProfile(obj: string) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.userCompanyProfile);
    } else {
      localStorage.setItem(STORAGE_KEYS.userCompanyProfile, obj);
    }
  }

  getUserCompanyProfile(): string | null {
    const profile = localStorage.getItem(
      STORAGE_KEYS.userCompanyProfile,
    ) as any;

    let user = this.getLocalUser();
    if(user?.profile.toString() == 'ADMIN') {
      return 'ADMIN';
    }

    if (profile == null) {
      return null;
    } else {
      return UserCompanyProfile.EXPOSITOR.toString() === profile
        ? UserCompanyProfile.EXPOSITOR
        : UserCompanyProfile.ORGANIZER;
    }
  }

  setContextStateLocation(obj: string | null) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.stateLocation);
    } else {
      localStorage.setItem(STORAGE_KEYS.stateLocation, obj);
    }
  }

  getContextStateLocation(): string | null {
    const state = localStorage.getItem(STORAGE_KEYS.stateLocation) as string;
    if (state == null) {
      return null;
    } else {
      return state;
    }
  }

  setContextCityLocation(obj: string | null) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.cityLocation);
    } else {
      localStorage.setItem(STORAGE_KEYS.cityLocation, obj);
    }
  }

  getContextCityLocation(): string | null {
    const state = localStorage.getItem(STORAGE_KEYS.cityLocation) as string;
    if (state == null) {
      return null;
    } else {
      return state;
    }
  }

  setRefreshToken(obj: string | null) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.refreshToken);
    } else {
      localStorage.setItem(STORAGE_KEYS.refreshToken, obj);
    }
  }

  getRefreshToken(): string | null {
    const state = localStorage.getItem(STORAGE_KEYS.refreshToken) as string;
    if (state == null) {
      return null;
    } else {
      return state;
    }
  }

  toggleUserProfilePreference(
    userProfilePreference: string,
    redirectUrl?: string,
  ) {
    this.setUserCompanyProfile(userProfilePreference!);
    this.onChangeUserCompanyProfile.emit(redirectUrl);
  }
}
